




















































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { HollowDotsSpinner } from 'epic-spinners';

import Scaffold from '@/components/Presenter/Scaffold.vue';
import ShareModal from '@/components/Presenter/ShareModal.vue';

export default Vue.extend({
  components: { Scaffold, HollowDotsSpinner, ShareModal },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPresenterSession',
      presenterSessionId
    );
    this.form = {
      name: this.$t('views.presenter.duplicateSessionAddCopyFormatted', {
        session: this.currentSession.name,
      }) as string,
      description: this.currentSession.description,
      startAt: this.currentSession.startAt.toDate(),
      endAt: this.currentSession.endAt.toDate(),
      sessionUnlimited: this.currentSession.sessionUnlimited || false,
      author: this.currentSession.author,
      authorName: this.currentSession.authorName,
      userGroup: this.currentSession.userGroup,
      shareToken: this.currentSession.shareToken,
      theme: this.currentSession.theme,
      reactions: this.currentSession.reactions,
      isPaused: this.currentSession.isPaused,
      deactivatedMenuItems: this.currentSession.deactivatedMenuItems,
      labels: this.currentSession.labels,
      descriptions: this.currentSession.descriptions,
    };
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        startAt: new Date(),
        sessionUnlimited: false,
        // current date + 24hours
        endAt: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
        author: '',
        authorName: '',
        userGroup: '',
        shareToken: '',
        theme: '',
        reactions: {},
        isPaused: false,
        deactivatedMenuItems: [],
        labels: {},
        descriptions: {},
      },
      now: new Date(),
      loading: false,
      showSuccessModal: false,
      isViewerData: false,
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      shareURL: 'presenter/getShareURL',
      shareToken: 'presenter/getSessionShareToken',
    }),
    isFormSubmitable(): boolean {
      return (
        this.form.name != '' &&
        this.form.startAt != this.now &&
        this.form.endAt != this.now &&
        (this.form.name != this.currentSession.name ||
          this.form.description != this.currentSession.description ||
          this.form.startAt != this.currentSession.startAt.toDate() ||
          this.form.endAt != this.currentSession.endAt.toDate())
      );
    },
  },
  methods: {
    async submitForm() {
      this.loading = true;
      await this.$store.dispatch('presenter/duplicateSession', {
        form: this.form,
        isViewerData: this.isViewerData,
        callback: this.callback,
      });
    },
    callback() {
      this.loading = false;
      this.showSuccessModal = true;
    },

    toggleViewrData() {
      this.isViewerData = !this.isViewerData;
    },
  },
});
